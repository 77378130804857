<template>
  <div>
      <el-row>
          <el-col>
              <el-form :inline='true'>
                  <!-- <el-form-item>
                      <v-distpicker 
                        :placeholders='placeholders'
                        :province='filters.province'
                        :city='filters.city'
                        :area='filters.area'
                        @province='selProvince'
                        @city='selCity'
                        @area='selArea'>
                      </v-distpicker>
                  </el-form-item> -->
                  <el-form-item>
                      <el-select v-model='filters.certificate'>
                          <el-option v-for='item in options' :key='item.value' :label='item.label' :value='item.value'></el-option>
                      </el-select>
                  </el-form-item>
                  <el-form-item>
                      <el-input v-model='filters.SocialUnitName' placeholder='输入单位名称' clearable @clear='handleClear'></el-input>
                  </el-form-item>
                  <el-form-item>
                      <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
                  </el-form-item>
              </el-form>             
          </el-col>
      </el-row>
      <!-- 表格内容 -->
      <el-table :data='tableData' border style='width:100%' :highlight-current-row='true'>
            <!-- <el-table-column type='selection' width='50'></el-table-column> -->
            <el-table-column prop='UnitName' label='单位名称'></el-table-column>
            <el-table-column prop='CertificateRegisterCount' label='证书数量'>
                <template slot-scope='scope'>
                    <router-link :to="{path:'/EnterDetails',query:{id:scope.row.ID}}" exact-active-class='active'>{{scope.row.CertificateRegisterCount}}</router-link>
                </template>
            </el-table-column>
            <el-table-column prop='ContactPerson' label='联系人'></el-table-column>
            <el-table-column prop='ContactMobilephone' label='手机'></el-table-column>
            <el-table-column prop='Tel' label='固定电话'></el-table-column>
            <el-table-column prop='Address' label='地址'></el-table-column>
            <el-table-column prop='Status' label='状态'>
                <template slot-scope='scope'>
                    <el-tag type='success' v-if='scope.row.Status = 0'>启用</el-tag>
                    <el-tag type='danger' v-else>禁用</el-tag>
                </template>
            </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pages.pageIndex"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pages.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pages.DataCount"
        class='pageBar'>
      </el-pagination>
  </div>
</template>

<script>
import Toolbar from "../../components/Toolbar";
import { getButtonList } from "../../promissionRouter";
import {getEnterpriseRegisterCertificateListPage,getCertificateInfoListPage} from '@/api/api'
import Qs from 'qs'
// 省市区
// import VDistpicker from 'v-distpicker'
export default {
    // components:{Toolbar,VDistpicker},
    components:{Toolbar},
    data(){
       return {
           buttonList: [],
           filters:{
               province:'',
               city:'',
               area:'',
               certificate:'',
               SocialUnitName:''
           },
            // 证书
           options:[
               {
                   value:'',
                   label:'请选择证书'
               }
           ], 
           tableData:[],
           pages:{
               pageIndex:1,
               pageSize:20,
               DataCount:0
           },
            // 控制跳转后文字颜色
           enterAfter:false,
        //     // 省市区县数据
        //    placeholders:{
        //        province:'选择省份',
        //        city:'选择市',
        //        area:'选择区县'
        //    }
       } 
    },
    methods:{
        // // 选择省市区县的处理函数
        // selProvince(value){
        //     this.filters.province = value.value
        //     console.log(this.filters.province)
        // },
        // selCity(value){
        //     this.filters.city = value.value
        //     console.log(this.filters.city)
        // },
        // selArea(value){
        //     this.filters.area = value.value
        //     console.log(this.filters.area)
        // },
        // 筛选项清空
        handleClear(){
            this.getData()
        },
        // 查询
        getEnterpriseRegisterCertificateListPage(){
            this.getData()
        },
        // 获取页面数据
        getData(){
            var params = {
                socialUnitName:this.filters.SocialUnitName,
                certificateDesignID:this.filters.certificate,
                pageIndex:this.pages.pageIndex,
                pageSize:this.pages.pageSize
            }
            getEnterpriseRegisterCertificateListPage(params).then(res => {
                if(res.data.Success){
                    this.tableData = res.data.Response.Data
                    this.pages.DataCount = res.data.Response.DataCount
                }
            })
        },
        // 获取所有证书名称
        getCertificateInfoData(){
            getCertificateInfoListPage().then(res => {
                if(res.data.Success){
                    var data = res.data.Response.Data                  
                    data.forEach(item => {
                        var obj = {}
                        obj.value = item.ID
                        obj.label = item.CertificateName
                        this.options.push(obj)
                    })                   
                }
            })
        },
        // 分页
        handleSizeChange(value){
            this.pages.pageSize = value
            this.getData()
        },
        handleCurrentChange(value){
            this.pages.pageIndex = value
            this.getData()
        },
        callFunction(item) {
            this[item.Func].apply(this, item);
        },
    },
    created(){
        this.getData()
        this.getCertificateInfoData()
        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
        this.buttonList = getButtonList(this.$route.path, routers);
    }
}
</script>

<style lang="stylus" scoped>
    // .pageBar{
    //     margin-top:15px!important;
    // }
    a{
        color:blue!important;
        text-decoration:underline!important;
    }
    .active{
        color:red!important;
    }
</style>